<template>
  <section id="main">
    <v-app v-if="!loading">
      <Topbar @close="drawer = !drawer" :user="user" />
      <Leftbar :drawer="drawer" :user="user" />
      <v-main :class="user.dark == 1 ? '' : 'main'">
        <v-container class="ma-1">
          <router-view />
        </v-container>
      </v-main>
    </v-app>
    <v-app v-else>
      <v-container fill-height>
        <v-row justify="center" align="center">
          <v-col cols="12" lg="4">
            <div class="d-flex justify-center align-center">
              <v-progress-circular
                indeterminate
                color="success"
              ></v-progress-circular>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-app>

    <!-- <v-app v-else>
      <Password :user="user" v-if="user.status == 0"
      @success="fetch"/>
      <v-container fill-height v-else>
        <v-row justify="center" align="center">
          <v-col cols="12" lg="4">
            <div class="d-flex justify-center align-center">
            <v-progress-circular
              indeterminate
              color="success"
            ></v-progress-circular>
          </div>
          </v-col>
        </v-row>
      </v-container>
    </v-app> -->
  </section>
</template>

<script>
import Topbar from "./Topbar.vue";
import Leftbar from "./Leftbar.vue";
// import Password from '../components/main/NewPassword'
import { mapState } from "vuex";

export default {
  components: {
    Topbar,
    Leftbar,
    // Password
  },
  data: () => ({
    drawer: null,
    loading: true,
  }),
  computed: {
    ...mapState("teacher", {
      user: (state) => state.user,
    }),
  },
  mounted() {
    this.$vuetify.theme.dark = true;
    this.fetch();
  },
  methods: {
    fetch() {
      this.$store.dispatch("teacher/authAction").then(() => {
        this.loading = false;
        this.$vuetify.theme.dark = true;
        if (!this.user.dark) {
          console.log("not dark");
          this.$vuetify.theme.dark = false;
        }
        return;
      });
    },
  },
};
</script>